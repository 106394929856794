<template>
  <div class='error-page'>
    <span class="error-tip">401</span>
    <div>
      <p>你的账号在其他设备登录，请重新登录!</p>
      <el-button type="text" @click="handleLogout">退出登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-401',
  methods: {
    handleLogout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({path: '/login'})
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.error-page {
  width: 100%;
  height: 100%;
  background-color: #172b53b8;
  text-align: center;
  .error-tip {
    display: inline-block;
    font-size: 200px;
    margin-top: 100px;
    color: #fff;
    letter-spacing: 20px;
  }
}
</style>
